export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_MESSAGE = "SET_MESSAGE";


export const USERTYPE_REQUEST = "USERTYPE_REQUEST";
export const USERTYPE_SUCCESS = "USERTYPE_SUCCESS";
export const USERTYPE_FAIL = "USERTYPE_FAIL";
