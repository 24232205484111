export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_MESSAGE = "SET_MESSAGE";

export const USERGETLIST_REQUEST = "USERGETLIST_REQUEST";
export const USERGETLIST_SUCCESS = "USERGETLIST_SUCCESS";
export const USERGETLIST_FAIL = "USERGETLIST_FAIL";

export const DELETEUSER_REQUEST = "DELETEUSER_REQUEST";
export const DELETEUSER_SUCCESS = "DELETEUSER_SUCCESS";
export const DELETEUSER_FAIL = "DELETEUSER_FAIL";

export const UPDATEUSER_REQUEST = "UPDATEUSER_REQUEST";
export const UPDATEUSER_SUCCESS = "UPDATEUSER_SUCCESS";
export const UPDATEUSER_FAIL = "UPDATEUSER_FAIL";
