export const ADDJOB_REQUEST = "ADDJOB_REQUEST";
export const ADDJOB_SUCCESS = "ADDJOB_SUCCESS";
export const ADDJOB_FAIL = "ADDJOB_FAIL";

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_MESSAGE = "SET_MESSAGE";

export const GET_SECTION_REQUEST = "GET_SECTION_REQUEST";
export const GET_SECTION_SUCCESS = "GET_SECTION_SUCCESS";
export const GET_SECTION_FAIL = "GET_SECTION_FAIL";


export const SUMMERY_REQUEST = "SUMMERY_REQUEST";
export const SUMMERY_SUCCESS = "SUMMERY_SUCCESS";
export const SUMMERY_FAIL = "SUMMERY_FAIL";