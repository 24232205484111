export const CANLIST_REQUEST = "CANLIST_REQUEST";
export const CANLIST_SUCCESS = "CANLIST_SUCCESS";
export const CANLIST_FAIL = "CANLIST_FAIL";

export const ADMSELECT_REQUEST = "ADMSELECT_REQUEST";
export const ADMSELECT_SUCCESS = "ADMSELECT_SUCCESS";
export const ADMSELECT_FAIL = "ADMSELECT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_MESSAGE = "SET_MESSAGE";
