export const GET_ALL_JOB_REQUEST = "GET_ALL_JOB_REQUEST";
export const GET_ALL_JOB_SUCCESS = "GET_ALL_JOB_SUCCESS";
export const GET_ALL_JOB_FAIL = "GET_ALL_JOB_FAIL";

export const ACCEPT_JOB_REQUEST = "ACCEPT_JOB_REQUEST";
export const ACCEPT_JOB_SUCCESS = "ACCEPT_JOB_SUCCESS";
export const ACCEPT_JOB_FAIL = "ACCEPT_JOB_FAIL";

export const GET_APPLIED_JOB_REQUEST = "GET_APPLIED_JOB_REQUEST";
export const GET_APPLIED_JOB_SUCCESS = "GET_APPLIED_JOB_SUCCESS";
export const GET_APPLIED_JOB_FAIL = "GET_APPLIED_JOB_FAIL";

export const GET_APPLIED_JOB_DETAILS_REQUEST =
  "GET_APPLIED_JOB_DETAILS_REQUEST";
export const GET_APPLIED_JOB_DETAILS_SUCCESS =
  "GET_APPLIED_JOB_DETAILS_SUCCESS";
export const GET_APPLIED_JOB_DETAILS_FAIL = "GET_APPLIED_JOB_DETAILS_FAIL";

export const GET_SECTION_REQUEST = "GET_SECTION_REQUEST";
export const GET_SECTION_SUCCESS = "GET_SECTION_SUCCESS";
export const GET_SECTION_FAIL = "GET_SECTION_FAIL";

export const HISTORY_REQUEST = "HISTORY_REQUEST";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";
export const HISTORY_FAIL = "HISTORY_FAIL";
