export const SELLIST_REQUEST = "SELLIST_REQUEST";
export const SELLIST_SUCCESS = "SELLIST_SUCCESS";
export const SELLIST_FAIL = "SELLIST_FAIL";

export const SELPASSLIST_REQUEST = "SELLIST_REQUEST";
export const SELPASSLIST_SUCCESS = "SELLIST_SUCCESS";
export const SELPASSLIST_FAIL = "SELLIST_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_MESSAGE = "SET_MESSAGE";
