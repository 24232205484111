export const CREATE_INTERVIEW_REQUEST = "CREATE_INTERVIEW_REQUEST";
export const CREATE_INTERVIEW_SUCCESS = "CREATE_INTERVIEW_SUCCESS";
export const CREATE_INTERVIEW_FAIL = "CREATE_INTERVIEW_FAIL";

export const CREATE_INTERVIEW_PANEL_REQUEST = "CREATE_INTERVIEW_PANEL_REQUEST";
export const CREATE_INTERVIEW_PANEL_SUCCESS = "CREATE_INTERVIEW_PANEL_SUCCESS";
export const CREATE_INTERVIEW_PANEL_FAIL = "CREATE_INTERVIEW_PANEL_FAIL";

export const UPDATE_INTERVIEW_REQUEST = "UPDATE_INTERVIEW_REQUEST";
export const UPDATE_INTERVIEW_SUCCESS = "UPDATE_INTERVIEW_SUCCESS";
export const UPDATE_INTERVIEW_FAIL = "UPDATE_INTERVIEW_FAIL";

export const GET_INTERVIEW_LIST_REQUEST = "GET_INTERVIEW_LIST_REQUEST";
export const GET_INTERVIEW_LIST_SUCCESS = "GET_INTERVIEW_LIST_SUCCESS";
export const GET_INTERVIEW_LIST_FAIL = "GET_INTERVIEW_LIST_FAIL";

export const GET_INTERVIEW_PANEL_LIST_FAIL = "GET_INTERVIEW_PANEL_LIST_FAIL";
export const GET_INTERVIEW_PANEL_LIST_REQUEST =
  "GET_INTERVIEW_PANEL_LIST_REQUEST";
export const GET_INTERVIEW_PANEL_LIST_SUCCESS =
  "GET_INTERVIEW_PANEL_LIST_SUCCESS";

export const UPDATE_FINAL_INTERVIEW_REQUEST = "UPDATE_FINAL_INTERVIEW_REQUEST";
export const UPDATE_FINAL_INTERVIEW_SUCCESS = "UPDATE_FINAL_INTERVIEW_SUCCESS";
export const UPDATE_FINAL_INTERVIEW_FAIL = "UPDATE_FINAL_INTERVIEW_FAIL";

export const GET_APPOINT_LIST_FAIL = "GET_APPOINT_LIST_FAIL";
export const GET_APPOINT_LIST_REQUEST = "GET_APPOINT_LIST_REQUEST";
export const GET_APPOINT_LIST_SUCCESS = "GET_APPOINT_LIST_SUCCESS";
