export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";

export const GET_DATA1_REQUEST = "GET_DATA1_REQUEST";
export const GET_DATA1_SUCCESS = "GET_DATA1_SUCCESS";
export const GET_DATA1_FAIL = "GET_DATA1_FAIL";

export const GET_DATA2_REQUEST = "GET_DATA2_REQUEST";
export const GET_DATA2_SUCCESS = "GET_DATA2_SUCCESS";
export const GET_DATA2_FAIL = "GET_DATA2_FAIL";

export const GET_DATA3_REQUEST = "GET_DATA3_REQUEST";
export const GET_DATA3_SUCCESS = "GET_DATA3_SUCCESS";
export const GET_DATA3_FAIL = "GET_DATA3_FAIL";

export const APPLY_JOB_REQUEST = "APPLY_JOB_REQUEST";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAIL = "APPLY_JOB_FAIL";
