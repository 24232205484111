export const STAFFAPLIST_REQUEST = "STAFFAPLIST_REQUEST";
export const STAFFAPLIST_SUCCESS = "STAFFAPLIST_SUCCESS";
export const STAFFAPLIST_FAIL = "STAFFAPLIST_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_MESSAGE = "SET_MESSAGE";

export const ADMSELECT_REQUEST = "ADMSELECT_REQUEST";
export const ADMSELECT_SUCCESS = "ADMSELECT_SUCCESS";
export const ADMSELECT_FAIL = "ADMSELECT_FAIL";
