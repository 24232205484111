export const GET_DATA1_Delete_REQUEST = "GET_DATA1_Delete_REQUEST";
export const GET_DATA1_Delete_SUCCESS = "GET_DATA1_Delete_SUCCESS";
export const GET_DATA1_Delete_FAIL = "GET_DATA1_Delete_FAIL";

export const GET_DATA2_Delete_REQUEST = "GET_DATA2_Delete_REQUEST";
export const GET_DATA2_Delete_SUCCESS = "GET_DATA2_Delete_SUCCESS";
export const GET_DATA2_Delete_FAIL = "GET_DATA2_Delete_FAIL";

export const GET_DATA3_Delete_REQUEST = "GET_DATA3_Delete_REQUEST";
export const GET_DATA3_Delete_SUCCESS = "GET_DATA3_Delete_SUCCESS";
export const GET_DATA3_Delete_FAIL = "GET_DATA3_Delete_FAIL";
